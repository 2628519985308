import _export from "../internals/export";
import _hasOwnProperty from "../internals/has-own-property";
import _isSymbol from "../internals/is-symbol";
import _tryToString from "../internals/try-to-string";
import _shared from "../internals/shared";
import _symbolRegistryDetection from "../internals/symbol-registry-detection";
var $ = _export;
var hasOwn = _hasOwnProperty;
var isSymbol = _isSymbol;
var tryToString = _tryToString;
var shared = _shared;
var NATIVE_SYMBOL_REGISTRY = _symbolRegistryDetection;
var SymbolToStringRegistry = shared("symbol-to-string-registry");

// `Symbol.keyFor` method
// https://tc39.es/ecma262/#sec-symbol.keyfor
$({
  target: "Symbol",
  stat: true,
  forced: !NATIVE_SYMBOL_REGISTRY
}, {
  keyFor: function keyFor(sym) {
    if (!isSymbol(sym)) throw TypeError(tryToString(sym) + " is not a symbol");
    if (hasOwn(SymbolToStringRegistry, sym)) return SymbolToStringRegistry[sym];
  }
});
export default {};