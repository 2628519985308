import _export from "../internals/export";
import _global3 from "../internals/global";
import _functionCall from "../internals/function-call";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _isPure from "../internals/is-pure";
import _descriptors from "../internals/descriptors";
import _symbolConstructorDetection from "../internals/symbol-constructor-detection";
import _fails from "../internals/fails";
import _hasOwnProperty from "../internals/has-own-property";
import _objectIsPrototypeOf from "../internals/object-is-prototype-of";
import _anObject from "../internals/an-object";
import _toIndexedObject from "../internals/to-indexed-object";
import _toPropertyKey from "../internals/to-property-key";
import _toString from "../internals/to-string";
import _createPropertyDescriptor from "../internals/create-property-descriptor";
import _objectCreate from "../internals/object-create";
import _objectKeys from "../internals/object-keys";
import _objectGetOwnPropertyNames from "../internals/object-get-own-property-names";
import _objectGetOwnPropertyNamesExternal from "../internals/object-get-own-property-names-external";
import _objectGetOwnPropertySymbols from "../internals/object-get-own-property-symbols";
import _objectGetOwnPropertyDescriptor from "../internals/object-get-own-property-descriptor";
import _objectDefineProperty from "../internals/object-define-property";
import _objectDefineProperties from "../internals/object-define-properties";
import _objectPropertyIsEnumerable from "../internals/object-property-is-enumerable";
import _defineBuiltIn from "../internals/define-built-in";
import _defineBuiltInAccessor from "../internals/define-built-in-accessor";
import _shared from "../internals/shared";
import _sharedKey from "../internals/shared-key";
import _hiddenKeys from "../internals/hidden-keys";
import _uid from "../internals/uid";
import _wellKnownSymbol from "../internals/well-known-symbol";
import _wellKnownSymbolWrapped from "../internals/well-known-symbol-wrapped";
import _wellKnownSymbolDefine from "../internals/well-known-symbol-define";
import _symbolDefineToPrimitive from "../internals/symbol-define-to-primitive";
import _setToStringTag from "../internals/set-to-string-tag";
import _internalState from "../internals/internal-state";
import _arrayIteration from "../internals/array-iteration";
var $ = _export;
var _global2 = _global3;
var call = _functionCall;
var uncurryThis = _functionUncurryThis;
var IS_PURE = _isPure;
var DESCRIPTORS = _descriptors;
var NATIVE_SYMBOL = _symbolConstructorDetection;
var fails = _fails;
var hasOwn = _hasOwnProperty;
var isPrototypeOf = _objectIsPrototypeOf;
var anObject = _anObject;
var toIndexedObject = _toIndexedObject;
var toPropertyKey = _toPropertyKey;
var $toString = _toString;
var createPropertyDescriptor = _createPropertyDescriptor;
var nativeObjectCreate = _objectCreate;
var objectKeys = _objectKeys;
var getOwnPropertyNamesModule = _objectGetOwnPropertyNames;
var getOwnPropertyNamesExternal = _objectGetOwnPropertyNamesExternal;
var getOwnPropertySymbolsModule = _objectGetOwnPropertySymbols;
var getOwnPropertyDescriptorModule = _objectGetOwnPropertyDescriptor;
var definePropertyModule = _objectDefineProperty;
var definePropertiesModule = _objectDefineProperties;
var propertyIsEnumerableModule = _objectPropertyIsEnumerable;
var defineBuiltIn = _defineBuiltIn;
var defineBuiltInAccessor = _defineBuiltInAccessor;
var shared = _shared;
var sharedKey = _sharedKey;
var hiddenKeys = _hiddenKeys;
var uid = _uid;
var wellKnownSymbol = _wellKnownSymbol;
var wrappedWellKnownSymbolModule = _wellKnownSymbolWrapped;
var defineWellKnownSymbol = _wellKnownSymbolDefine;
var defineSymbolToPrimitive = _symbolDefineToPrimitive;
var setToStringTag = _setToStringTag;
var InternalStateModule = _internalState;
var $forEach = _arrayIteration.forEach;
var HIDDEN = sharedKey("hidden");
var SYMBOL = "Symbol";
var PROTOTYPE = "prototype";
var setInternalState = InternalStateModule.set;
var getInternalState = InternalStateModule.getterFor(SYMBOL);
var ObjectPrototype = Object[PROTOTYPE];
var $Symbol = _global2.Symbol;
var SymbolPrototype = $Symbol && $Symbol[PROTOTYPE];
var TypeError = _global2.TypeError;
var QObject = _global2.QObject;
var nativeGetOwnPropertyDescriptor = getOwnPropertyDescriptorModule.f;
var nativeDefineProperty = definePropertyModule.f;
var nativeGetOwnPropertyNames = getOwnPropertyNamesExternal.f;
var nativePropertyIsEnumerable = propertyIsEnumerableModule.f;
var push = uncurryThis([].push);
var AllSymbols = shared("symbols");
var ObjectPrototypeSymbols = shared("op-symbols");
var WellKnownSymbolsStore = shared("wks");

// Don't use setters in Qt Script, https://github.com/zloirock/core-js/issues/173
var USE_SETTER = !QObject || !QObject[PROTOTYPE] || !QObject[PROTOTYPE].findChild;

// fallback for old Android, https://code.google.com/p/v8/issues/detail?id=687
var setSymbolDescriptor = DESCRIPTORS && fails(function () {
  return nativeObjectCreate(nativeDefineProperty({}, "a", {
    get: function () {
      return nativeDefineProperty(this, "a", {
        value: 7
      }).a;
    }
  })).a !== 7;
}) ? function (O, P, Attributes) {
  var ObjectPrototypeDescriptor = nativeGetOwnPropertyDescriptor(ObjectPrototype, P);
  if (ObjectPrototypeDescriptor) delete ObjectPrototype[P];
  nativeDefineProperty(O, P, Attributes);
  if (ObjectPrototypeDescriptor && O !== ObjectPrototype) {
    nativeDefineProperty(ObjectPrototype, P, ObjectPrototypeDescriptor);
  }
} : nativeDefineProperty;
var wrap = function (tag, description) {
  var symbol = AllSymbols[tag] = nativeObjectCreate(SymbolPrototype);
  setInternalState(symbol, {
    type: SYMBOL,
    tag: tag,
    description: description
  });
  if (!DESCRIPTORS) symbol.description = description;
  return symbol;
};
var $defineProperty = function defineProperty(O, P, Attributes) {
  if (O === ObjectPrototype) $defineProperty(ObjectPrototypeSymbols, P, Attributes);
  anObject(O);
  var key = toPropertyKey(P);
  anObject(Attributes);
  if (hasOwn(AllSymbols, key)) {
    if (!Attributes.enumerable) {
      if (!hasOwn(O, HIDDEN)) nativeDefineProperty(O, HIDDEN, createPropertyDescriptor(1, {}));
      O[HIDDEN][key] = true;
    } else {
      if (hasOwn(O, HIDDEN) && O[HIDDEN][key]) O[HIDDEN][key] = false;
      Attributes = nativeObjectCreate(Attributes, {
        enumerable: createPropertyDescriptor(0, false)
      });
    }
    return setSymbolDescriptor(O, key, Attributes);
  }
  return nativeDefineProperty(O, key, Attributes);
};
var $defineProperties = function defineProperties(O, Properties) {
  anObject(O);
  var properties = toIndexedObject(Properties);
  var keys = objectKeys(properties).concat($getOwnPropertySymbols(properties));
  $forEach(keys, function (key) {
    if (!DESCRIPTORS || call($propertyIsEnumerable, properties, key)) $defineProperty(O, key, properties[key]);
  });
  return O;
};
var $create = function create(O, Properties) {
  return Properties === undefined ? nativeObjectCreate(O) : $defineProperties(nativeObjectCreate(O), Properties);
};
var $propertyIsEnumerable = function propertyIsEnumerable(V) {
  var P = toPropertyKey(V);
  var enumerable = call(nativePropertyIsEnumerable, this, P);
  if (this === ObjectPrototype && hasOwn(AllSymbols, P) && !hasOwn(ObjectPrototypeSymbols, P)) return false;
  return enumerable || !hasOwn(this, P) || !hasOwn(AllSymbols, P) || hasOwn(this, HIDDEN) && this[HIDDEN][P] ? enumerable : true;
};
var $getOwnPropertyDescriptor = function getOwnPropertyDescriptor(O, P) {
  var it = toIndexedObject(O);
  var key = toPropertyKey(P);
  if (it === ObjectPrototype && hasOwn(AllSymbols, key) && !hasOwn(ObjectPrototypeSymbols, key)) return;
  var descriptor = nativeGetOwnPropertyDescriptor(it, key);
  if (descriptor && hasOwn(AllSymbols, key) && !(hasOwn(it, HIDDEN) && it[HIDDEN][key])) {
    descriptor.enumerable = true;
  }
  return descriptor;
};
var $getOwnPropertyNames = function getOwnPropertyNames(O) {
  var names = nativeGetOwnPropertyNames(toIndexedObject(O));
  var result = [];
  $forEach(names, function (key) {
    if (!hasOwn(AllSymbols, key) && !hasOwn(hiddenKeys, key)) push(result, key);
  });
  return result;
};
var $getOwnPropertySymbols = function (O) {
  var IS_OBJECT_PROTOTYPE = O === ObjectPrototype;
  var names = nativeGetOwnPropertyNames(IS_OBJECT_PROTOTYPE ? ObjectPrototypeSymbols : toIndexedObject(O));
  var result = [];
  $forEach(names, function (key) {
    if (hasOwn(AllSymbols, key) && (!IS_OBJECT_PROTOTYPE || hasOwn(ObjectPrototype, key))) {
      push(result, AllSymbols[key]);
    }
  });
  return result;
};

// `Symbol` constructor
// https://tc39.es/ecma262/#sec-symbol-constructor
if (!NATIVE_SYMBOL) {
  $Symbol = function Symbol() {
    if (isPrototypeOf(SymbolPrototype, this)) throw TypeError("Symbol is not a constructor");
    var description = !arguments.length || arguments[0] === undefined ? undefined : $toString(arguments[0]);
    var tag = uid(description);
    var setter = function (value) {
      if (this === ObjectPrototype) call(setter, ObjectPrototypeSymbols, value);
      if (hasOwn(this, HIDDEN) && hasOwn(this[HIDDEN], tag)) this[HIDDEN][tag] = false;
      setSymbolDescriptor(this, tag, createPropertyDescriptor(1, value));
    };
    if (DESCRIPTORS && USE_SETTER) setSymbolDescriptor(ObjectPrototype, tag, {
      configurable: true,
      set: setter
    });
    return wrap(tag, description);
  };
  SymbolPrototype = $Symbol[PROTOTYPE];
  defineBuiltIn(SymbolPrototype, "toString", function toString() {
    return getInternalState(this).tag;
  });
  defineBuiltIn($Symbol, "withoutSetter", function (description) {
    return wrap(uid(description), description);
  });
  propertyIsEnumerableModule.f = $propertyIsEnumerable;
  definePropertyModule.f = $defineProperty;
  definePropertiesModule.f = $defineProperties;
  getOwnPropertyDescriptorModule.f = $getOwnPropertyDescriptor;
  getOwnPropertyNamesModule.f = getOwnPropertyNamesExternal.f = $getOwnPropertyNames;
  getOwnPropertySymbolsModule.f = $getOwnPropertySymbols;
  wrappedWellKnownSymbolModule.f = function (name) {
    return wrap(wellKnownSymbol(name), name);
  };
  if (DESCRIPTORS) {
    // https://github.com/tc39/proposal-Symbol-description
    defineBuiltInAccessor(SymbolPrototype, "description", {
      configurable: true,
      get: function description() {
        return getInternalState(this).description;
      }
    });
    if (!IS_PURE) {
      defineBuiltIn(ObjectPrototype, "propertyIsEnumerable", $propertyIsEnumerable, {
        unsafe: true
      });
    }
  }
}
$({
  global: true,
  constructor: true,
  wrap: true,
  forced: !NATIVE_SYMBOL,
  sham: !NATIVE_SYMBOL
}, {
  Symbol: $Symbol
});
$forEach(objectKeys(WellKnownSymbolsStore), function (name) {
  defineWellKnownSymbol(name);
});
$({
  target: SYMBOL,
  stat: true,
  forced: !NATIVE_SYMBOL
}, {
  useSetter: function () {
    USE_SETTER = true;
  },
  useSimple: function () {
    USE_SETTER = false;
  }
});
$({
  target: "Object",
  stat: true,
  forced: !NATIVE_SYMBOL,
  sham: !DESCRIPTORS
}, {
  // `Object.create` method
  // https://tc39.es/ecma262/#sec-object.create
  create: $create,
  // `Object.defineProperty` method
  // https://tc39.es/ecma262/#sec-object.defineproperty
  defineProperty: $defineProperty,
  // `Object.defineProperties` method
  // https://tc39.es/ecma262/#sec-object.defineproperties
  defineProperties: $defineProperties,
  // `Object.getOwnPropertyDescriptor` method
  // https://tc39.es/ecma262/#sec-object.getownpropertydescriptors
  getOwnPropertyDescriptor: $getOwnPropertyDescriptor
});
$({
  target: "Object",
  stat: true,
  forced: !NATIVE_SYMBOL
}, {
  // `Object.getOwnPropertyNames` method
  // https://tc39.es/ecma262/#sec-object.getownpropertynames
  getOwnPropertyNames: $getOwnPropertyNames
});

// `Symbol.prototype[@@toPrimitive]` method
// https://tc39.es/ecma262/#sec-symbol.prototype-@@toprimitive
defineSymbolToPrimitive();

// `Symbol.prototype[@@toStringTag]` property
// https://tc39.es/ecma262/#sec-symbol.prototype-@@tostringtag
setToStringTag($Symbol, SYMBOL);
hiddenKeys[HIDDEN] = true;
export default {};